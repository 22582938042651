import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavController, Platform} from '@ionic/angular';
import {HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {Activity} from "../../models/Activity";
import {AlertService} from '../../providers/alert/alert.service';
import {LocalStorageService} from '../../providers/local-storage/local-storage.service';
import {ParserService} from "../../providers/parser/parser.service";
import {HelperService} from "../../providers/helper/helper.service";
import {RequestProviderService} from "../../providers/request-provider/request-provider.service";
import {TokenInterceptorService} from "../../providers/token-interceptor/token-interceptor.service";
import { BACKEND_URL } from "../../../environments/environment";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Input() title: string;
    @Input() back = false;
    @Input() save = false;
    @Input() answersChanged: boolean;
    @Input() confirmBack = false;
    @Input() icon: string;
    @Output('saveEmitter') saveEmitter: EventEmitter<Event> = new EventEmitter<Event>();
    @Output('backEmitter') backEmitter: EventEmitter<Event> = new EventEmitter<Event>();
    @Output('changeLanguageEmitter') changeLanguageEmitter: EventEmitter<Event> = new EventEmitter<Event>();

    private language: string;
    public smallScreen: boolean = false;
    private length: number = 0;
    private loadedActivities: Array<Activity> = [];

    constructor(
      private navCtrl: NavController,
      private router: Router,
      private alertService: AlertService,
      private translateService: TranslateService,
      public localStorage: LocalStorageService,
      private platform: Platform,
      private requestProvider: RequestProviderService,
      public parserService: ParserService,
      private helperService: HelperService,
      private tokenInterceptorService: TokenInterceptorService,
    ) {
    }

    ngOnInit() {
      //for page refresh
      document.body.classList.toggle('darkTheme', this.localStorage.getTheme() == '0');
      document.documentElement.style.setProperty('--backgroundImage', 'url("../../../assets/imgs/' + this.localStorage.getBackground() + '.jpg")');

      this.router.events.subscribe((ev) => {
        if (ev instanceof NavigationEnd) {
          this.language = this.localStorage.getAppLanguage();
          this.translateService.use(this.language);
        }
      });

      if(this.platform.width() < 992){
        this.smallScreen = true;
      }

      this.platform.resize.subscribe(() => {
        if(this.platform.width() < 992){
          this.smallScreen = true;
        }else{
          this.smallScreen = false;
        }
      });
    }

    menuOpened(){
      this.loadedActivities = [];
      const infiniteScroll = document.getElementById('scrollActivities');
      console.log(infiniteScroll)

      infiniteScroll.addEventListener('ionInfinite', async function (){
        if(this.length < this.parserService.activityLog.length){
          console.log('Loading data...');
          await this.wait(500);
          //infiniteScroll.target.complete();
          this.appendItems(10);
          console.log('Done');
        }else{
          console.log('No More Data');
          //infiniteScroll.target.disabled = true;
        }
      });

      this.appendItems(30);
    }

    appendItems(number: number){
      console.log('length is', this.length);
      const originalLength = this.length;
      for (var i = 0; i < number; i++){
        this.loadedActivities.push(this.parserService.activityLog[i + originalLength]);
        this.length++;
      }
      console.log(this.loadedActivities)
    }

    wait(time: number):Promise<void>{
      return new Promise((resolve) => {
        setTimeout(() => resolve, time);
      });
    }

    changeLanguage(selectedLanguage) {
      if (selectedLanguage !== this.language) {
        this.language = selectedLanguage;
        this.translateService.use(this.language);
        this.localStorage.setAppLanguage(this.language);
        this.tokenInterceptorService.cancelPendingRequests();
        this.changeLanguageEmitter.emit();
      }
    }

    logout() {
      let id = this.localStorage.getDeviceTokenId();
      if(id != -1){
        this.requestProvider.deleteDeviceToken(id).pipe(map((result) => result), catchError(error => {return throwError(error)}))
          .subscribe((response) => {
            this.logoutRequest();
          }, (error) => {
            this.logoutRequest();
          });
      }else{
        this.logoutRequest();
      }
    }

    logoutRequest(){
      this.localStorage.removeDeviceTokenId();
      this.requestProvider.logout().pipe(map((result) => result), catchError(error => {return throwError(error)}))
        .subscribe((response) => {
          this.tokenInterceptorService.cancelPendingRequests();
          this.localStorage.removeToken();
          this.localStorage.removeUserEmail();
          this.localStorage.removeLocalUser();
          this.localStorage.removeUserId();
          this.parserService.newMessages = 0;
          this.parserService.newFeedback = 0;
          this.parserService.showAnnouncements = false;
          this.parserService.selectedAnnouncement = 0;
          this.navCtrl.navigateRoot('/login', {animated: true, animationDirection: 'forward'}); //use this to reset root so that no data of previously logged in user is still there todorouting
          // this.router.navigate(['/login'], {replaceUrl: true});
        }, (error) => {
          if (JSON.stringify(error) == '\"no internet connection.\"' || (error instanceof HttpErrorResponse && error.status == 401)) {
            this.localStorage.removeToken();
            this.localStorage.removeUserEmail();
            this.localStorage.removeLocalUser();
            this.localStorage.removeUserId();
            this.navCtrl.navigateRoot('/login', {animated: true, animationDirection: 'forward'}); //use this to reset root so that no data of previously logged in user is still there todorouting
          }else{
            this.alertService.showError('ERROR.ERROR', 'ERROR.ERROR_SOMETHING_WENT_WRONG');
          }
        });
    }

    private refresh() {
      this.requestProvider.refreshToken().then((response) => {
        if (response instanceof HttpResponse && response.status == 200) {
          this.localStorage.setToken(response.body.data.attributes.token);
        }
      }).catch((error) => {
        this.alertService.showError('ERROR.ERROR', 'ERROR.ERROR_TOKEN_REFRESH');
      });
    }

    goBack() {
      this.navCtrl.back();
    }

    routeToInvitations(){
      this.router.navigateByUrl('/invitations');
    }

    saveQuestionnaire(){
      this.saveEmitter.emit();
    }

    showHideAnnouncements(){
      this.parserService.showAnnouncements = !this.parserService.showAnnouncements;
    }

    previousAnnouncement(){
      this.parserService.selectedAnnouncement--;
    }

    nextAnnouncement(){
      this.parserService.selectedAnnouncement++;
    }

    emit() {}

    get isMobile(): boolean { return this.platform.is('mobileweb'); }

    get isDevelopVersion(): boolean { return !BACKEND_URL.includes("esano"); }
}
